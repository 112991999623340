//import React, { useState, useEffect } from 'react';


//import axios from 'axios';
//import base from './data.json';
//import './navbar.scss'
function Menu(props) {

    function openMenu() {
        const navbar = document.getElementById('nav-bar')
        if (navbar.classList.contains('open-main-menu')) {
            navbar.classList.remove('open-main-menu')
        } else {
            navbar.classList.add('open-main-menu')
        }
    }
    return (
        <nav id='nav-bar' className='App-main-menu'>
            <a title="Web dizajn i programiranje - AWD Dizajn" href="#">
                <figure>
                    <img width="55" height="55" src="https://awd.rs/frontend/web/uploads/master/web-dizajn--razvoj-i-seo-optimizacija_logo.svg" alt="Web dizajn - programiranje - SEO optimizacija | AWD Dizajn" title="Web dizajn - programiranje - SEO - internet-marketing" />
                </figure>
            </a>
            <a onClick={openMenu} title="Glavni meni" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                </svg>
            </a>
        <ul>
                {props.menu.filter(item => item.menu_option === '1').map(item => (
              
                <li key={item.id}>
                    <a href={`${'/' + item.link_sr_latn }` }/* !== 'web' ? '/' + item.link_sr_latn : '/'*/>{item.menu_title_sr_latn}</a>                   
                </li>
               
            ))}
        </ul>
        </nav>
    );
}

export default Menu;
