//import Navbar from './components/navbar.js';
//import Menu from './menu.js';
//import Hero from './hero'
//import Footer from './components/footer.js'
//import Articles from './components/articles.js'
//import React, { useState, useEffect } from 'react';
//import axios from 'axios';
//import './style.scss'
//import './header.scss';
//import base from './data.json';
//import React, { useState, useEffect } from "react";
//‍import { Helmet } from 'react-helmet';
//import Helmet from "helmet";
import MetaTags from 'react-meta-tags';
import { useLocation } from 'react-router-dom';
function Page(props) {
    const imgSrc = "https://auto-savke.com/frontend/web/uploads/master/" + props.img;
    const location = useLocation();
    const pathname = location.pathname;
    const mainUrl = window.location.origin;

    document.documentElement.lang = "sr";




    //const [data, setData] = useState([]);
   
    /*
    //const [title, setTitle] = useState('');
    const titleElement = document.createElement('title');

    //const [description, setDescription] = useState('');
    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";

    const ogTitle = document.createElement("meta");
    ogTitle.setAttribute('property', 'og:title');
    
 

    
    useEffect(() => {
        titleElement.textContent = props.title;
        metaDescription.content = props.description;
        ogTitle.content = props.title;
      
        document.head.appendChild(titleElement);
        document.head.appendChild(metaDescription);
        document.head.appendChild(ogTitle);

    }, []);
    */


    const firstSection = (
        <section key="first-section">
            {props.homedata.filter(item => item.page_option === "3").map(item => (
                <div key={item.id}>
                    <header>
                        <h2 dangerouslySetInnerHTML={{ __html: item.title_sr_latn }} /> 
                        <div dangerouslySetInnerHTML={{ __html: item.text_sr_latn }} />
                    </header>
                </div>
            ))}
        </section>
    );

    const secSection = (
        <section key="sec-section">
            {
                props.homedata.filter(item => item.menu_option === "0" && item.page_option !== "3").map(item => (
                    <div key={item.id}>
                        <header>
                            <h2 dangerouslySetInnerHTML={{ __html: item.title_sr_latn }} /> 
                            <div dangerouslySetInnerHTML={{ __html: item.text_sr_latn }} />
                        </header>
                    </div>
                ))
            }
        </section>
    )
    
    return (
        <>
            <MetaTags>
                <title>{ props.title}</title>
                <meta name="description" content={props.description} />
                <meta name="author" content="[Coa Luđak]" />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={mainUrl + pathname} />
                <meta property="og:site_name" content="PiSi Dizajn"/>
                <meta property="og:title" content={props.title} />
                <meta property="og:description" content={props.description} />
                <meta property="og:image" content={imgSrc} />

                <meta property="twitter:title" content={props.title} />
                <meta property="twitter:card" content={props.title} />
                <meta property="twitter:description" content={props.description} />
                <meta property="twitter:image" content={imgSrc} />
            </MetaTags>
           
            <header className="App-main-header">
                <figure>
                    <img id="blur-img" src={imgSrc} title="Web dizajn - programiranje - SEO - internet-marketing" alt="Web dizajn - programiranje - SEO optimizacija | AWD Dizajn" className="blur-img" />
                </figure>
                <div>
                    <div className="mh-left">
                    </div>
                    <div className="mh-right">

                        <h1>{props.title}</h1>
                        <p>Aktivni URL: {mainUrl  + pathname}</p>
                        
                        <div itemProp="description">
                            <div dangerouslySetInnerHTML={{ __html: props.text }} />
                        </div>
                    </div>
                </div>
            </header>
            

                    {/**
                    <Hero img={imgSrc}
                    title={props.title}
                    description = {props.text} />
            <header className="App-main-header">
                <figure>
                    <img id="blur-img" src={imgSrc} title="Web dizajn - programiranje - SEO - internet-marketing" alt="Web dizajn - programiranje - SEO optimizacija | AWD Dizajn" className="blur-img"/>
                </figure>
                <div>
                    <div className="mh-left">
                    </div>
                    <div className="mh-right">
                        
                        <h1>{props.title}</h1>
                        <div itemProp="description">
                            <div dangerouslySetInnerHTML={{ __html: props.text }} />
                        </div>
                    </div>
                </div>
            </header>
             */}

            {props.link === '' ? (

                <div>
                    {firstSection}
                    {secSection}
                </div>


            ) : null
            }
          
              

            <ul>
                {JSON.parse(props.slave).map(slave => (
                    <li className="" key={slave.id}>
                        {slave.title  ? (
                            <header>
                                <h2>{slave.title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: slave.text }} />
                                {slave.subslaves && <a href={`/${props.link}/${slave.link}`}>{ slave.title}</a>}                                                                    
                 
                              
                            </header>
                        ):(
                        <div dangerouslySetInnerHTML={{ __html: slave.text }} />
                        )}                        
                        {slave.image && <img style={{ width:'100%' }} with="300" height="300" src={`https://awd.rs/frontend/web/uploads/master/${slave.image}`} alt={slave.title} />}                                                                    
                    </li>
                ))}

            </ul>
           
        </>
        )
}
export default Page